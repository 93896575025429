* {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body,
input,
textarea,
select {
  color: #000;
  font-family: "Work Sans", sans-serif;
}

a {
  color: #40b4e5;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html,
body,
#root,
.app {
  height: 100%;
}

.page__loader {
  margin: 100px auto;
  text-align: center;
}

.error {
  color: #c00;
}

.back {
  padding: 20px 0 0 20px;
}

.form--login {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
}

.form--login fieldset {
  max-width: 400px;
}

.form--md {
  max-width: 900px;
}

.form p {
  margin: 0 0 20px 0;
}

.fieldset {
  padding: 10px 0;
}

.button {
  background: #40b4e5;
  border-radius: 999px;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 15px 30px;
  opacity: 0.9;
  text-transform: uppercase;
}

.input {
  background: #fafafa;
  border: 1px solid #cbcbcb;
  border-radius: 999px;
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  width: 100%;
}

textarea.input {
  border-radius: 10px;
  font-size: 1rem;
  height: 120px;
  line-height: normal;
  padding: 10px 15px;
}

.select {
  border: 1px solid #333;
  border-radius: 999px;
  font-size: 1rem;
}

.select--after {
  margin: 0 0 0 10px;
}

.label {
  display: block;
  padding: 0 0 3px 10px;
  text-transform: uppercase;
}

.label--radio {
  padding: 0 0 10px 0;
  text-transform: none;
}

.label--radio input {
  margin: 0 5px 0 0;
}

.label--plain {
  padding: 0 0 10px 0;
  text-transform: none;
}

.button:hover {
  opacity: 1;
}

.app {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.wrapper {
  margin: 0 auto;
  max-width: 1170px;
  position: relative;
  width: 100%;
}

.header {
  background-color: #402a58;
  color: #fff;
  height: 60px;
  line-height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.header a {
  color: #fff;
}

.header a:hover {
  text-decoration: underline;
}

.header__logo {
  left: 20px;
  position: absolute;
}

.header__logo img {
  height: 60px;
}

.header__title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.header__logout {
  float: right;
  padding: 0 15px;
}

.header__cta {
  float: right;
  padding: 0 15px;
}

.header__cta .button {
  border: 1px solid #fff;
  padding: 10px 20px;
}

.page {
  flex: 2;
}

.page--columns {
  display: flex;
  height: 100%;
}

.page__heading {
  border-bottom: 1px solid #f0f0f0;
  font-size: 2.4rem;
  font-weight: 500;
  padding: 50px 20px 20px 20px;
}

.page__content {
  padding: 20px;
}

.page__intro {
  margin: 0 0 40px 0;
}

.page__cta {
  margin: 50px 0 0 0;
}

.content {
  flex: 1;
  padding: 20px 20px 100px 20px;
  overflow: auto;
}

.content__container {
  flex: 2;
  flex-direction: row;
  z-index: 1;
}

.content__heading {
  border-bottom: 1px solid #f0f0f0;
  margin: 0 -20px 20px -20px;
  padding: 0 20px 10px 20px;
  position: relative;
}

.content__heading__title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.content__heading__from {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.content__heading__date {
  font-size: 0.8125rem;
  font-weight: 300;
}

.content__attachments {
  margin: 30px 0 0 0;
}

.content__attachments__title {
  font-weight: 500;
  text-transform: uppercase;
}

.content__attachments__docs {
  padding: 15px 0 0 0;
}

.content .page__heading {
  margin: -20px -20px 10px -20px;
}

.subheading {
  font-size: 1rem;
  font-weight: 500;
  margin: 20px 0 10px 0;
  text-transform: uppercase;
}

.page_submit {
  margin: 30px 0 0 0;
}

article {
  line-height: 1.5rem;
}

article a {
  color: #40b4e5;
}

article a:hover {
  text-decoration: underline;
}

article h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 30px 0 10px 0;
}

article h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 20px 0 10px 0;
}

article p {
  margin: 0 0 15px 0;
}

article ul,
article ol {
  margin: 0 0 20px 30px;
}

article li {
  margin: 0 0 15px 0;
}

article dl {
  border-bottom: 1px solid #efefef;
  margin: 0 0 30px 0;
  overflow: auto;
  padding: 0 0 20px 0;
}

article dt {
  clear: left;
  float: left;
  font-weight: 700;
  margin: 0 0 1px 0;
  padding: 5px 10px;
  width: 120px;
}

article dd {
  float: left;
  margin: 0 0 1px 0;
  padding: 5px 10px;
}

article .email {
  box-shadow: 0 0 5px #999;
  max-width: 1100px;
  padding: 20px;
}

article img {
  max-width: 100%;
}

article table {
  margin: 0 0 30px 0;
}

article td {
  border: 1px solid #ccc;
  padding: 10px;
}

article hr {
  border-top: 1px solid #ddd;
}

article .icon {
  display: inline-block;
  text-align: center;
  width: 30px;
}

article.preview {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0 0 20px 0;
  max-height: 300px;
  overflow: auto;
  padding: 20px;
}

.mobile-menu {
  display: none;
}

.modal {
  background: rgba(255, 255, 255, 0.98);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal__content {
  margin: 0 auto;
  max-width: 640px;
  padding: 80px 50px;
}

.modal__close {
  background: url(/assets/icons/close.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 30px;
  right: 30px;
  position: fixed;
  top: 30px;
  width: 30px;
}

.table {
  margin: 15px 0 20px 0;
}

.table th {
  background: #f1f1f1;
  padding: 8px 16px;
  text-align: left;
}

.table td {
  border: 1px solid #f1f1f1;
  min-width: 150px;
  padding: 8px 16px;
}

.table .table__cell--clear {
  border: 0;
  min-width: 0;
}

.remove {
  background: url(/assets/icons/close.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  width: 12px;
}

h2 {
  font-size: 1.5rem;
  margin: 0 0 10px 0;
}

h3 {
  font-size: 1.2rem;
  margin: 0 0 10px 0;
}

.terms__option {
  margin: 0 0 0 26px;
  position: relative;
  top: -20px;
}

.tabs {
  padding: 30px 20px 10px 20px;
}

.tabs__tab {
  border: 1px solid;
  border-radius: 999px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 10px 15px;
}

.tabs__tab--active {
  color: #fff;
  background-color: #402a58;
}

.page__content table {
  border-collapse: collapse;
  font-size: 0.8rem;
  margin: 10px 0 30px 0;
  width: 100%;
}

.page__content td {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 4px 10px;
}

.page__content tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.page__content th {
  background-color: #40b4e5;
  border-bottom: 1px solid #ccc;
  color: #fff;
  padding: 4px 10px;
  text-align: left;
}

.research__nomination {
  margin: 20px 0;
}

.research__nomination__info {
  margin: 30px 0 40px 0;
}

.research__survey {
  margin: 20px 0;
}

.research__survey__item {
  background: #f8f8f8;
  border-radius: 10px;
  margin: 0 0 20px 0;
  padding: 20px;
  position: relative;
}

.research__survey__item h3 {
  font-size: 20px;
}

.research__survey__item h4 {
  font-size: 16px;
}

.research__survey__item--completed {
  background: #edf3e6;
}

.research__survey__item--completed a,
.research__survey__item--completed .research__survey__item__completeness {
  color: #2ab361;
}

.research__survey__item__completeness {
  color: #c00;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 30px;
}

.research__survey__item__completeness span {
  font-size: 16px;
}

.survey__info {
  background: #f2f2f2;
  border-radius: 5px;
  margin: 20px;
  padding: 15px;
}

.survey__description {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 20px 0 20px;
  padding: 15px;
}

.survey__question {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0 0 20px 0;
  padding: 20px;
}

.survey__question strong {
  font-size: 1.2rem;
}

.survey__question--completed {
  background: #e2f2ed;
  border-color: #c5e1d8;
}

.survey__question--error {
  background: #f2e2e2;
  border-color: #e1c5c5;
}

.survey__question__options {
  margin: 20px 0 0 0;
}

.survey__answer {
  font-size: 0.9rem;
  padding: 0 20px 0 0;
}

.survey__question__input {
  margin: 10px 0 0 0;
}

.survey__question__input .error {
  font-size: 0.8rem;
  margin: 0 0 10px 0;
}

.survey__question__input__check {
  margin: 10px 0 0 0;
}

.survey__question__input__check input {
  margin: 0 6px 0 0;
}

.survey__submit {
  margin: 0 0 100px 0;
  padding: 30px;
  text-align: center;
}
